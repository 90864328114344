.ql-container {
    min-height: 300px;
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
}

.ql-editor {
    height: 100%;
    flex: 1;
    overflow-y: auto;
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.richtextWrap {
    position: 'relative'; /* fix the toolbar position */
    :global(.ql-editor) {
        /* fix the default theme color */
        blockquote,
        div,
        h2,
        h3,
        h4,
        ol,
        p,
        ul {
            color: '[theme:black, default:black]';
        }
    }
}
